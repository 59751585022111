@use "../config" as *;
.waf-squad {
    @extend %relative;
    &::after {
        content: "";
        width: var(--window-inner-width);
        z-index: var(--z-bg-gradient);
        pointer-events: none;
        @include background(null, "pattern/white-bg.png", top / contain);
        @extend %h-100;
        @extend %pos-center;
    }
    .filter-section {
        @extend %d-none;
    }
    .waf-body {
        @extend %mt-10;
    }
    .squad {
        &-head {
            top: var(--space-8);
            @extend %relative;
        }
        &-title {
            -webkit-text-fill-color: var(--neutral-50);
            text-shadow: 0 0 .1rem var(--neutral-900);
            line-height: 4rem;
            @extend %font-45-pb;
            @extend %capitalize;
            @extend %relative;
            @extend %d-inline-block;
            &::after {
                content: "";
                background-image: linear-gradient(to top, var(--neutral-50), transparent);
                @include position(null, null, 0, 0, absolute);
                @extend %w-100;
                @extend %h-50;
            }
        }
        &-item {
            flex-shrink: 0;
            @extend %hidden;
            @extend %relative;
            @extend %w-100;
            @extend %pt-8;
            @extend %full-radius;
            &.captain.overseas {
                .player-wrap::before {
                    left: var(--space-11);
                }
            }
            &.captain {
                .player-wrap::before {
                    content: "C";
                    @extend %font-16-pb;
                    @extend %pure-white-900;
                    @extend %accent-900-bg;
                }
            }
            &.overseas {
                .player-wrap::after {
                    @include icon(overseas);
                    @extend %neutral-900;
                    @extend %neutral-50-bg;
                }
            }
            .toggle-stats {
                // z-index: var(--z-toggle-stats);
                @extend %font-0;
                @extend %h-100;
                @extend %w-100;
                @include position(0, 0, 0, 0, absolute);
                &[aria-expanded=true] ~ .player-content {
                    bottom: 0;
                    @include background(clr(primary-900), "pattern/player-content-bg.png", top / cover no-repeat);
                    .player-name {
                        &::after {
                            left: 0;
                            width: 80%;
                        }
                    }
                }
            }
        }
        &-wrapper {
            @extend %flex-wrap;
            @extend %flex;
            @extend %gap-3;
        }
        &-group:not(:first-child) {
            @extend %mt-3-neg;
        }
    }
    .player {
        &-thumbnail {
            height: 40rem;
            pointer-events: none;
            @extend %mt-12-neg;
        }
        &-wrap {
            @include background(null, "pattern/squad-listing-bg.jpg", top / cover no-repeat);
            @extend %relative;
            @extend %p-3;
            @extend %full-radius;
            &::before,
            &::after {
                width: 2.5rem;
                height: 2.5rem;
                @include position(2rem, null, null, 2rem, absolute);
                @extend %flex-c-c;
                @extend %circle-radius;
            }
        }
        &-content {
            bottom: -17.5rem;
            @extend %transition;
            @extend %p-3;
            @extend %w-100;
            @extend %pos-bl;
            @extend %secondary-900-bg-9;
        }
        &-stats-list {
            @extend %flex-wrap;
            @extend %flex-c-n;
            @extend %gap-3;
            @extend %mt-4;
        }
        &-stats-item {
            flex: 1;
            flex-direction: column-reverse;
            @extend %full-radius;
            @extend %p-2;
            @extend %gap-3;
            @extend %secondary-900-bg-4;
            @extend %flex-c-c;
            @extend %relative;
            &::after {
                content: "";
                height: 0.1rem;
                width: 80%;
                @extend %pos-center;
                @extend %neutral-50-bg-2;
            }
        }
        &-stats-count {
            height: 3rem;
            @extend %flex-n-c;
            @extend %text-center;
            @extend %tertiary-800;
            @extend %font-16-pb;
        }
        &-stats-title {
            height: 3.5rem;
            @extend %flex-n-c;
            @extend %text-center;
            @extend %pure-white-900;
            @extend %capitalize;
            @extend %font-12-pr;
        }
        &-name {
            @extend %flex-wrap;
            @extend %flex;
            @extend %gap-1;
            @extend %relative;
            @extend %mb-5;
            .name {
                @extend %font-18-pb;
                @extend %tertiary-800;
            }
            &::after {
                content: "";
                height: 0.1rem;
                @include position(null,null,-1.2rem,0);
                // @extend %pos-x-center;
                @extend %neutral-50-bg-2;
                @extend %w-100;
            }
        }
        &-meta {
            @extend %flex-n-c;
            @extend %gap-4;
        }
        &-role {
            @extend %flex;
            @extend %gap-1;
            .role {
                @extend %font-14-pr;
                @extend %flex;
                @extend %pure-white-900;
                @extend %capitalize;
            }
        }
        &-country {
            @extend %relative;
            &:before {
                content: '';
                width: .1rem;
                left: var(--space-2-neg);
                @extend %pure-white-900-bg;
                @extend %h-100;
                @extend %pos-y-center;
            }
        }
    }
    .country {
        &-flag {
            @extend %d-none;
        }
        &-name {
            @extend %pure-white-900;
            @extend %font-14-pr;
        }
    }
    .card-action {
        @extend %flex;
        @extend %w-100;
        @extend %gap-2;
        .btn-site {
            flex: 1;
            border: .1rem solid clr(tertiary-600);
            @extend %half-radius;
            @extend %d-block;
            @extend %font-14-pb;
            @extend %uppercase;
            @extend %p-2;
            @extend %tertiary-600;
            @extend %text-center;
            @extend %transition;
            &:hover {
                @extend %tertiary-600-bg;
                @extend %neutral-900;
            }
        }
    }
    .tab-section {
        top: 10rem;
        @extend %absolute;
        @extend %w-100;
        .tab {
            &s {
                @extend %x-scroll;
                @extend %flex;
                @extend %gap-3;
            }
            &-name {
                height: 4.4rem;
                border: .1rem solid var(--secondary-600);
                cursor: pointer;
                @extend %w-100;
                @extend %half-radius;
                @extend %flex-c-c;
                @extend %px-3;
                &.active {
                    border: .1rem solid var(--tertiary-600);
                    font-weight: 700;
                    @extend %tertiary-600-bg;
                    @extend %secondary-800;
                    span {
                        font-weight: 700;                        
                    }
                }
                span {
                    width: max-content;
                    @extend %capitalize;
                    @extend %neutral-800;
                    @extend %font-14-pm;
                }
            }
        }
    }
    .support-staff {
        .player {
            &-stats-list {
                @extend %d-none;
            }
            &-content {
                bottom: var(--space-0);
            }
        }
    }
}
@include mq(col-md) {
    .waf-squad {
        .waf-body {
            margin-top: var(--space-12);
        }
        .squad {
            &-title {
                font-size: 9rem;
                line-height: 9rem;
            }
            &-wrapper {
                @include card-count(2, var(--space-3));
                overflow: unset;
            }
            &-item {
                &:hover {
                    .player-content {
                        bottom: 0;
                        animation: 1s both bounceInBottom;
                        @include background(clr(primary-900), "pattern/player-content-bg.png", top / cover no-repeat);
                    }
                    .player-name {
                        &::after {
                            left: 0;
                            width: 80%;
                        }
                    }
                }
            }
        }
        .player {
            &-content {
                transition: 0.1s;
            }
            &-stats-list {
                display: flex;
            }
        }
        .toggle-stats {
            display: none;
        }
        .tab-section {
            top: 12rem;
        }
    }
}
@include mq(col-lg) {
    .waf-squad {
        .squad {
            &-wrapper {
                @include card-count(3, var(--space-3));
                overflow: unset;
            }
        }
    }
}
@include mq(col-xl) {
    .waf-squad {
        .squad {
            &-title {
                font-size: 10rem;
            }
            &-wrapper {
                @include card-count(4, var(--space-3));
                overflow: unset;
            }
        }
    }
}